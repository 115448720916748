import Auth from './auth'

import './middleware'

// Active schemes
import scheme_003d9a64 from './schemes/local.js'
import scheme_23514a38 from './schemes/oauth2.js'

export default function (ctx, inject) {
  // Options
  const options = {"resetOnError":false,"scopeKey":"scope","rewriteRedirects":true,"fullPathRedirect":false,"watchLoggedIn":true,"redirect":{"login":"/login","logout":"/","home":"/","callback":"/login"},"vuex":{"namespace":"auth"},"cookie":false,"localStorage":{"prefix":"auth."},"token":{"prefix":"_token."},"refresh_token":{"prefix":"_refresh_token."},"defaultStrategy":"local"}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // local
  $auth.registerStrategy('local', new scheme_003d9a64($auth, {"endpoints":{"login":{"url":"/auth/login","method":"post","propertyName":"token"},"logout":{"url":"/auth/logout","method":"post"},"user":{"url":"/user/me","method":"get","propertyName":"user"},"refresh":false},"_name":"local"}))

  // google
  $auth.registerStrategy('google', new scheme_23514a38($auth, {"client_id":"","scope":["profile","email"],"_name":"google","authorization_endpoint":"https://accounts.google.com/o/oauth2/auth","userinfo_endpoint":"https://www.googleapis.com/oauth2/v3/userinfo"}))

  // facebook
  $auth.registerStrategy('facebook', new scheme_23514a38($auth, {"client_id":"","scope":["public_profile","email"],"_name":"facebook","authorization_endpoint":"https://facebook.com/v2.12/dialog/oauth","userinfo_endpoint":"https://graph.facebook.com/v2.12/me?fields=about,name,picture{url},email"}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      console.error('[ERROR] [AUTH]', error)
    }
  })
}
